export default {
  data() {
    return {
      rules: {
        nameRule: [v => !!v || 'Enter your name'],
        emailRule: [
          v => !!v || 'Enter a valid email',
          v => /.+@.+\..+/.test(v) || 'Enter a valid email'
        ],
        requiredRule: [v => !!v || 'This field is required'],
        phoneRule: [v => !!v || 'Enter a valid phone number'],
        passwordRule: [v => !!v || 'Enter a valid password'],
        questionRule: [
          v => v == true || v == false || 'This field is required'
        ],
        positiveNumber: [
          v => !v || (v && v >= 0) || 'This field must be a positive number'
        ],
        costRule: [v => !v || (v && v >= 0) || 'Cost can not be negative'],
        mobileRule: [
          v =>
            !v || (v && this.isMobileNumber(v)) || 'Enter a valid mobile number'
        ]
      }
    }
  },
  methods: {
    isMobileNumber(num) {
      let regex = new RegExp(/^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/)
      return regex.test(num)
    },
    positiveNumberRule(val, name) {
      if (!name) {
        name = 'This field'
      }

      if (val && val < 0) {
        return `${name} can not be negative`
      } else {
        return ''
      }
    }
  }
}
